<template>
  <v-card>
    <v-card-title>
      Duplicatas por Orçamento
      <v-spacer />
    </v-card-title>
    <v-data-table
      dense
      :headers="headers"
      :items="listaDuplicata"
    >
      <template v-slot:item.vlDup="{ item }">
        {{ formatPrice(item.vlDup) }}
      </template>
    </v-data-table>
    <v-divider />
    <v-row>
      <v-col
        cols="12"
      >
        <v-card-title>
          Lista de Pix Pagos
          <v-spacer />
        </v-card-title>
        <v-data-table
          dense
          :headers="headersPix"
          :items="listaPixPagos"
        >
          <template v-slot:item.vlPix="{ item }">
            {{ formatPrice(item.vlPix) }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-divider />
    <v-card-title>
      <v-row class="d-flex justify-center">
        <v-col
          cols="8"
          md="3"
        >
          <v-text-field
            v-model="valorPix"
            v-currency="currencyPix"
            label="Valor do Pix"
            dense
            outlined
          />
        </v-col>
      </v-row>
      <v-btn
        depressed
        color="primary"
        @click="gerarPagamento"
      >
        Gerar PIX
      </v-btn>
    </v-card-title>
    <v-dialog
      v-model="dialogRecebCaixa"
      max-width="1000"
      persistent
    >
      <modalRecebCaixa
        v-if="dialogRecebCaixa"
        :idpix="idInsertPix"
        @onSairRecebeCaixaRota="onSairRecebeCaixaRota"
      />
    </v-dialog>
  </v-card>
</template>
<script>
  import duplicataRepository from './../../components/services/duplicatasRepository'
  import pixRepository from './../../components/services/pixRepository'
  import modalRecebCaixa from '../transacoes/caixa/recebcaixa.vue'
  export default {
    components: {
      modalRecebCaixa,
    },
    props: {
      codigopedido: {
        type: Number,
        required: true,
      },
      valororcamento: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        dialogRecebCaixa: false,
        listaDuplicata: [],
        listaPixPagos: [],
        totalDuplicata: null,
        valorPix: null,
        idInsertPix: null,
        headers: [
          {
            text: 'Duplicata',
            align: 'center',
            value: 'duplicata',
            width: 130,
          },
          {
            text: 'Vl. Dup',
            align: 'end',
            value: 'vlDup',
            width: 200,
          },
        ],
        headersPix: [
          {
            text: 'Valor Pago',
            value: 'vlPix',
            align: 'end',
            width: 130,
          },
        ],
        currencyPix: {
          currency: null,
          valueAsInteger: true,
          distractionFree: false,
          precision: 2,
          autoDecimalMode: true,
          allowNegative: false,
        },
      }
    },
    mounted () {
      this.getDuplicatasOrcamento()
      this.getPixPagamentos()
    },
    methods: {
      getDuplicatasOrcamento () {
        duplicataRepository.getOrcamentoDuplicatas(this.codigopedido).then((resp) => {
          this.totalDuplicata = resp.data.total
          this.valorPix = this.formatPrice(String(resp.data.total))
          this.listaDuplicata = resp.data.lista
        }).catch(() => {
          this.$swal({
            icon: 'warning',
            text: 'Existe mais de uma duplicata para esse cliente, forma de pagamento não autorizada!',
            confirmButtonText: 'OK',
          }).then((result) => {
            if (result.isConfirmed) {
              this.$emit('onSairDuplicata')
            }
          })
        })
      },
      getPixPagamentos () {
        pixRepository.getPagamentos(this.codigopedido).then((resp) => {
          this.listaPixPagos = resp.data
        }).catch(() => {
          this.$swal({
            icon: 'warning',
            text: 'Erro ao Consultar os Dados',
          })
        })
      },
      gerarPagamento () {
        if (this.validarGeracaoPix()) {
          var data = {
            cdOrcamento: this.codigopedido,
            vlPix: parseFloat(this.valorPix.replace('.', '').replace(',', '.')),
            stUtilizado: 'R', // Rota
          }
          pixRepository.postNovoPix(data).then((resp) => {
            this.idInsertPix = resp.data
            this.dialogRecebCaixa = true
          }).catch((error) => {
            this.$swal({
              icon: 'error',
              text: error.detail,
              confirmButtonText: 'OK',
            }).then((result) => {
              if (result.isConfirmed) return false
            })
          })
        }
      },
      validarGeracaoPix () {
        if (parseFloat(this.valorPix.replace('.', '').replace(',', '.')) > this.totalDuplicata) {
          this.$swal({
            icon: 'warning',
            text: 'Valor do Pix é maior que Valor da Soma das Duplicatas',
          })
          return false
        }
        return true
      },
      onSairRecebeCaixaRota () {
        this.valorPix = '0,00'
        this.idInsertPix = 0
        this.dialogRecebCaixa = false
        this.getPixPagamentos()

        this.$swal({
          icon: 'success',
          text: 'Pix Confirmado!',
          confirmButtonText: 'OK',
        }).then(() => { })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
</script>
