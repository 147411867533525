<template>
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card
      outlined
    >
      <v-card-title>Orçamentos Rota</v-card-title>
      <v-card-text class="mt-2">
        <v-row>
          <v-col
            cols="12"
            md="2"
            lg="2"
          >
            <v-text-field
              v-model="idRota"
              label="Rota"
              placeholder="Número da Rota"
              outlined
              dense
              hide-details
              append-icon="mdi-account-search-outline"
              @click:append="onCarregarGridPesquisa"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-card>
      <v-card-title>
        Lista de Orçamentos
        <v-spacer />
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="listaOrcamento"
      >
        <template v-slot:item.vlTotal="{ item }">
          {{ formatPrice(item.vlTotal) }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon
            small
            class="mr-2"
            @click="onClick(item)"
          >
            mdi-truck-fast-outline
          </v-icon>
          <v-icon
            small
            class="mr-2 ml-5"
            @click="onClickMensagem(item)"
          >
            mdi-message
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog
      v-model="dialogDuplicatas"
      max-width="1000"
    >
      <modalDuplicatas
        v-if="dialogDuplicatas"
        :codigopedido="codigopedido"
        :valororcamento="valororcamento"
        @onSairDuplicata="onSairDuplicata"
      />
    </v-dialog>
    <v-dialog
      v-model="dialogMensagemRota"
      max-width="600"
    >
      <modalMensagemRota
        v-if="dialogMensagemRota"
        :id="codigopedido"
        @onSairEnvioMensagem="onSairEnvioMensagem"
      />
    </v-dialog>
  </v-container>
</template>
<script>
  import rotaCaixaRep from '../../../components/services/rotaRepository'
  import transacaoRep from '../../../components/services/transacaoRepository'
  import modalDuplicatas from '../../componentes/modal-rotaduplicatas.vue'
  import modalMensagemRota from '../../componentes/modal-mensagemrota.vue'

  export default {
    components: {
      modalDuplicatas,
      modalMensagemRota,
    },
    data () {
      return {
        idRota: null,
        codigopedido: null,
        valororcamento: null,
        dialogDuplicatas: false,
        dialogMensagemRota: false,
        listaOrcamento: [],
        headers: [
          {
            text: 'Orçamento',
            align: 'center',
            value: 'orcamento',
            width: 130,
          },
          {
            text: 'Cliente',
            align: 'start',
            value: 'nmCliente',
            width: 200,
          },
          {
            text: 'Vl. Total',
            align: 'end',
            value: 'vlTotal',
            width: 200,
          },
          { text: '', value: 'actions', width: 100, align: 'end', sortable: false },
        ],
      }
    },
    async created () {
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        await this.getTransacao()
      }
    },
    methods: {
      onClick (item) {
        this.codigopedido = item.cdOrcamento
        this.valororcamento = item.vlTotal
        this.dialogDuplicatas = true
      },
      async onCarregarGridPesquisa () {
        rotaCaixaRep.getListaOrcamentosRota(this.idRota).then((resp) => {
          this.listaOrcamento = resp.data
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      async getTransacao () {
        this.$store.commit('setSplashScreen', true)
        var dados = {
          usuario: this.$store.getters.usuario.cdusuario,
          transacao: 'FP163PIX',
        }
        await transacaoRep.get(dados).then(response => {
          if (response.data.status === 'mensagem') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'warning',
              text: response.data.dados,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) {
                return false
              }
            })
          } else if (response.data.status === 'error') {
            this.$store.commit('setSplashScreen', false)
            this.$swal({
              icon: 'error',
              text: response.data.messagem,
              confirmButtonText: 'OK',
            }).then((result) => {
              this.acessoLiberado = false
              if (result.isConfirmed) {
                return false
              }
            })
          } else {
            this.$store.commit('setSplashScreen', false)
            this.acessoLiberado = true
          }
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      onClickMensagem (item) {
        this.codigopedido = item.cdOrcamento
        this.dialogMensagemRota = true
      },
      onSairDuplicata () {
        this.dialogDuplicatas = false
      },
      onSairEnvioMensagem () {
        this.dialogMensagemRota = false
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
</script>
