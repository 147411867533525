import Repository from './repository'

const resource = '/rota'

export default {

  getListaOrcamentosRota (id) {
    return Repository.get(`${resource}?idrota=${id}`)
  },

  postMensagem (data) {
    return Repository.post(`${resource}/mensagem-orcamento`, data)
  },
}
