import Repository from './repository'

const resource = '/pix'

export default {

    postNovoPix (data) {
        return Repository.post(`${resource}`, data)
    },
    getPagamentos (data) {
        return Repository.get(`${resource}/pororcamento?CdOrcamento=${data}`)
    },
}
