<template>
  <v-card>
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-toolbar-title>
          Mensagem Rota Orçamento
        </v-toolbar-title>
        <v-spacer />
        <v-btn
          class="mx-2"
          small
          fab
          color="amber"
          @click="onSalvar"
        >
          <v-icon class="white--text">
            mdi-content-save-all
          </v-icon>
        </v-btn>
        <v-btn
          class="mx-2"
          small
          fab
          color="light-green accent-4"
          @click="onSair"
        >
          <v-icon class="white--text">
            mdi-exit-to-app
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text>
        <v-textarea
          v-model="txMensagemOrcamento"
          name="input-7-1"
          label="Registro de informações"
          hint="Mensagem"
          counter="150"
        />
      </v-card-text>
    </v-card>
  </v-card>
</template>
  <script>
  import rotaRep from './../../components/services/rotaRepository'
  export default {
    props: {
      id: {
        type: Number,
        default: 0,
        required: false,
      },
    },
    data () {
      return {
        txMensagemOrcamento: '',
        orcamento: {
          cdOrcamento: 0,
          txMotorista: '',
        },
      }
    },
    methods: {
      async onSalvar () {
        try {
          this.$store.commit('setSplashScreen', true)
          this.orcamento.cdOrcamento = this.id
          this.orcamento.txMotorista = this.txMensagemOrcamento
          await rotaRep.postMensagem(this.orcamento).then(response => {
            this.$emit('onSairEnvioMensagem')
            this.$store.commit('setSplashScreen', false)
          }).catch(error => {
            this.$swal({
              icon: 'error',
              text: error,
            })
          })
        } catch (error) {

        }
      },
      onSair () {
        this.$emit('onSairEnvioMensagem')
      },
    },
  }
  </script>
